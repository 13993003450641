/* changes here should also be made in src/styles/variables.css */

const colors = {
  white: '#FFFFFF',
  red: '#DE5B62',

  blue: '#3095a3',
  bluedark: '#2F3B53',
  bluedarkest: '#252E40',

  greylight: '#EBEBEB',
  greyruler: '#d2d2d2',
  grey: '#828282',
  greydark: '#515151',
}

export default colors
